import { ReactComponent as ShareArrowIcon } from '@brand/icons/share-arrow.svg'
import clsx from 'clsx'
import styles from './icon-ctas.module.css'
import type { MouseEventHandler } from 'react'
import { memo } from 'react'
import { Button } from '../button/button'

interface ShareButtonProps {
  'data-tag_selection'?: string
  'data-tag_action'?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  isAGListingCard?: boolean
  isRentListingCard?: boolean
}

export const ShareButton = memo(function ShareButton({
  onClick,
  className,
  isAGListingCard,
  isRentListingCard,
  ...props
}: ShareButtonProps) {
  const classNameDerived = isRentListingCard
    ? styles.withRentStyles
    : clsx(styles.iconCtaStyle, styles.default, {
        [styles.shareButtonAG]: isAGListingCard,
      })

  const Component = isRentListingCard ? Button : 'button'

  return (
    <Component
      data-tag_item="share_button"
      data-tag_selection={props['data-tag_selection']}
      data-tag_action={props['data-tag_action']}
      data-tid="share"
      className={clsx(className, classNameDerived)}
      aria-label="share"
      onClick={onClick}
      variant={isRentListingCard ? 'rentSrpTertiary' : undefined}
      rounded={isAGListingCard}
    >
      <ShareArrowIcon aria-hidden />
    </Component>
  )
})
