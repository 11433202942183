import { ReactComponent as HeartFilledIcon } from '@brand/icons/heart-filled.svg'
import { ReactComponent as HeartIcon } from '@brand/icons/heart.svg'
import clsx from 'clsx'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useRef, useState } from 'react'
import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import {
  emailCaptureFavoriteCountAtom,
  emailPromptShownAtom,
} from '../../features/email-capture/email-capture.store'
import { ModalName } from '../../features/modals/modals.config'
import { useOpenModal } from '../../features/modals/use-open-modal'
import {
  lastSavedListingIdAtom,
  useIsLoggedIn,
  userSavedListingIds,
  useZutronId,
} from '../../features/user/user.store'
import { createUserAddFavoriteListingFetcher } from './__generated__/add-favorite.gql'
import { createUserRemoveFavoriteListingFetcher } from './__generated__/remove-favorite.gql'
import styles from './icon-ctas.module.css'
import { useSubmitUserActivityHandler } from '../../features/user-event-tracking/use-submit-user-activity-handler'
import { Button } from '../button/button'

type FavoriteIconProps = {
  isFavorite: boolean
  hasBecomeFavored: boolean
}

function FavoriteIcon({ isFavorite, hasBecomeFavored }: FavoriteIconProps) {
  return isFavorite ? (
    <HeartFilledIcon
      aria-hidden
      className={clsx(styles.filledHeart, {
        [styles.clickAnimation]: hasBecomeFavored,
      })}
    />
  ) : (
    <HeartIcon aria-hidden />
  )
}

interface FavoriteButtonProps {
  listingId: string
  onChange?: (isAdding: boolean) => void | Promise<void>
  onShowModal?: () => void
  className?: string
  isMarkerPopUp?: boolean
  isRentListingCard?: boolean
  isAGListingCard?: boolean
  showSavedText?: boolean
}

const FAVORITE_THRESHOLD = 1

const addFavorite = createUserAddFavoriteListingFetcher(graphqlRequesterOptions)
const removeFavorite = createUserRemoveFavoriteListingFetcher(
  graphqlRequesterOptions
)

export function FavoriteButton({
  listingId,
  onChange,
  onShowModal,
  className,
  isMarkerPopUp,
  isAGListingCard,
  isRentListingCard,
  showSavedText,
}: FavoriteButtonProps) {
  const [loading, setLoading] = useState(false)
  const hasBecomeFavored = useRef(false)
  const [favorites, setFavorites] = useAtom(userSavedListingIds)
  const isFavorite = favorites.includes(listingId)
  const savedTaggingText = isFavorite ? 'unsave' : 'save'
  const favoriteLabelText = isFavorite ? 'Saved' : 'Save'
  const zutronId = useZutronId()
  const submitUserActivityFavorite = useSubmitUserActivityHandler({
    listingId,
    type: 'favorited',
  })
  const showFullRentButton = isRentListingCard && !isMarkerPopUp
  const setLastSavedListingId = useSetAtom(lastSavedListingIdAtom)
  const openModal = useOpenModal()
  const setEmailCaptureFavoriteCount = useSetAtom(emailCaptureFavoriteCountAtom)
  const hasEmailPromptBeenShown = useAtomValue(emailPromptShownAtom)
  const userIsLoggedIn = useIsLoggedIn()

  // Keep track in a mutable ref - I don't want updating this value to re-render the component
  const favoriteClicks = useRef<number>(0)

  // if the user favorites more than one item on the page,
  // show the email-capture form and store the listing id.

  const handleFavoriteToggle = useCallback(async () => {
    if (listingId && zutronId && !loading) {
      setLoading(true)
      if (isFavorite) {
        await removeFavorite({
          listingId,
          zutronId,
        }).tapOk(() => {
          setFavorites(
            favorites.filter((existingId) => existingId !== listingId)
          )
        })
      } else {
        hasBecomeFavored.current = true
        await addFavorite({ listingId, zutronId }).tapOk(() => {
          void submitUserActivityFavorite()
          setLastSavedListingId(listingId)

          favoriteClicks.current++

          if (
            favoriteClicks.current >= FAVORITE_THRESHOLD &&
            !hasEmailPromptBeenShown &&
            !userIsLoggedIn
          ) {
            openModal({
              id: ModalName.EMAIL_CAPTURE,
              props: {
                listingId,
                modalSource: 'modal_impression',
              },
            })
            setEmailCaptureFavoriteCount(favoriteClicks.current)
            onShowModal?.()
          }

          setFavorites(favorites.concat([listingId]))
        })
      }
      await onChange?.(!isFavorite)
      setLoading(false)
    }
  }, [
    zutronId,
    favorites,
    hasEmailPromptBeenShown,
    isFavorite,
    listingId,
    onChange,
    onShowModal,
    openModal,
    setEmailCaptureFavoriteCount,
    setFavorites,
    setLastSavedListingId,
    submitUserActivityFavorite,
    userIsLoggedIn,
    loading,
  ])

  const buttonStateCls = isFavorite ? styles.filled : styles.default

  const componentClass = isRentListingCard
    ? styles.withRentStyles
    : clsx(styles.iconCtaStyle, buttonStateCls, {
        [styles.favoriteButtonAG]: isAGListingCard,
      })

  const Component = showFullRentButton ? Button : 'button'

  return (
    <Component
      disabled={loading}
      data-tag_item="favorite_properties"
      data-tag_selection={savedTaggingText}
      data-tid={`Favorite-${isFavorite ? 'on' : 'off'}`}
      className={clsx(componentClass, className, styles.favoriteButton)}
      aria-label={`${savedTaggingText} favorite`}
      onClick={handleFavoriteToggle}
      variant={showFullRentButton ? 'rentSrpTertiary' : undefined}
      rounded={isAGListingCard}
    >
      <FavoriteIcon
        isFavorite={isFavorite}
        hasBecomeFavored={hasBecomeFavored.current}
      />
      {showFullRentButton || showSavedText ? (
        <span className={styles.favoriteLabelText}>{favoriteLabelText}</span>
      ) : null}
    </Component>
  )
}
