import { useMemo } from 'react'
import { CTAButtons } from '../../../../features/cta-buttons/cta-buttons'
import { EMAIL } from '../../../../features/cta-buttons/cta-buttons.const'
import type { ListingCardCTAProps } from '../../../../features/listing-card/listing-card.types'
import { useHideRequestTour } from '../../../../features/detail/use-hide-request-tour'
import styles from './listing-card-cta.module.css'

export function ListingCardCTA(props: ListingCardCTAProps) {
  const hideRequestTour = useHideRequestTour(
    props.listing.availabilityStatus,
    props.listing.tourProviderDetails,
    props.listing.requestTourOptOut
  )

  const listingCardCTAs = useMemo(() => {
    return hideRequestTour ? (
      <>
        <CTAButtons.PhoneCTA
          variant="outlined"
          className={styles.ctas}
          hideIcon
        />
        <CTAButtons.EmailCTA
          data-tid="cta-email-property-lead"
          variant="primary"
          label={EMAIL}
          fluid
          hideIcon
          className={styles.ctas}
        />
      </>
    ) : (
      <>
        <CTAButtons.EmailCTA
          data-tid="cta-email-property-lead"
          variant="outlined"
          label={EMAIL}
          hideIcon
          className={styles.ctas}
        />
        <CTAButtons.TourCTA
          data-tid="cta-schedule-a-tour"
          fluid
          variant="primary"
          className={styles.ctas}
          withAlternateAgIcon
        />
      </>
    )
  }, [hideRequestTour])

  return (
    <CTAButtons
      data-tid="cta-buttons"
      isMobile={props.isMobile}
      className={props.ctaClass}
      listing={props.listing}
      currentRefinementSlugs={props.currentRefinementSlugs}
      locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
    >
      {listingCardCTAs}
    </CTAButtons>
  )
}
